/* nunito-sans-regular - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/nunito-sans-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/nunito-sans-v11-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/nunito-sans-v11-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/nunito-sans-v11-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/nunito-sans-v11-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/nunito-sans-v11-latin-regular.svg#NunitoSans')
      format('svg'); /* Legacy iOS */
}

/* nunito-sans-700 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/nunito-sans-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('./fonts/nunito-sans-v11-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/nunito-sans-v11-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/nunito-sans-v11-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/nunito-sans-v11-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/nunito-sans-v11-latin-700.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-800 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/nunito-sans-v11-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''), url('./fonts/nunito-sans-v11-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/nunito-sans-v11-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/nunito-sans-v11-latin-800.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/nunito-sans-v11-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/nunito-sans-v11-latin-800.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-900 - latin */
@font-face {
  font-family: 'Nunito Sans Black';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/nunito-sans-v11-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''), url('./fonts/nunito-sans-v11-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/nunito-sans-v11-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/nunito-sans-v11-latin-900.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/nunito-sans-v11-latin-900.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/nunito-sans-v11-latin-900.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

body {
  overflow-x: hidden;
  /* CssBaseline adds a custom color on body. Here we reset it to white */
  background-color: #ffffff !important;
}

/**
 * Recharts injects an svg to calc text width. The class of the inner text is recharts_measurement_span
 * This svg was causing problems on the loader and introduced scroll behavior in wrong places.
 * This is a hack that works as a workaround. On recharts@2.x.x it seems they have got rid of this approach.
 * TODO: update recharts version (when ready)
 */
body > svg {
  position: absolute;
  left: -999999px;
}

.lazyload-wrapper {
  height: 100%;
}

div#CybotCookiebotDialog {
  box-shadow: 0 0 29.8px 5.3px rgba(0, 0, 0, 0.1);

  & div,
  & a {
    font-family: Roboto;
  }

  & #CybotCookiebotDialogBodyContentTitle {
    font-family: Roboto;
    font-size: 20px;
  }

  & a#CybotCookiebotDialogBodyLevelButtonAccept {
    background-color: #2b55c5;
    border: 1px solid #2b55c5;
    padding: 5px 2px;
    margin-top: 5px;
    border-radius: 13.5px;
    font-weight: 700;

    &:hover {
      color: #2b55c5;
      background-color: #fff;
    }
  }

  & #CybotCookiebotDialogBodyLevelDetailsWrapper {
    background: transparent;
    border: none;
  }

  & #CybotCookiebotDialogBodyLevelButtonsSelectPane {
    border: none;
  }
}
